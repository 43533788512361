import React, { Fragment } from "react"
import Layout from "../components/layout"
import Article from "../components/article"
import { graphql } from "gatsby"
import { ILocation } from './../interface/location';

const Forretningsforsel: React.FC<{ location: ILocation; data: any }> = ({
    location,
    data,
}) => {
    const {
        content,
        ingress,
        pagename,
        heading,
        subheading,
        contact,
        contactEmail,
        contactTel,
        contactPicture,
    } = data.forretningsforsel.edges[0].node.frontmatter
    const ingressData = { ingress, pagename }

    return (
        <Fragment>
            <Layout location={location} hero={true} ingress={ingressData}>
                <Article
                    heading={heading}
                    subtitle={subheading}
                    content={content}
                    contact={contact}
                    contactEmail={contactEmail}
                    contactTel={contactTel}
                    contactPicture={contactPicture.childImageSharp.gatsbyImageData}
                    slimImage={true}
                />
            </Layout>
        </Fragment>
    );
}

export default Forretningsforsel

export const data = graphql`query Forretningsforsel {
  forretningsforsel: allMarkdownRemark(
    filter: {fileAbsolutePath: {regex: "/CMSContent/Subpages/forretningsførsel/"}}
  ) {
    edges {
      node {
        frontmatter {
          pagename
          heading
          ingress
          subheading
          content
          contact
          contactEmail
          contactTel
          contactPicture {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
}
`
